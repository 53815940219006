import React, { Fragment, useContext } from 'react'
import { PageProps } from 'docz'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import MainContext from '@theme/contexts/MainContext'
import { EditOnGithub, HeroImage, TableOfContents } from '@theme/components'

const Document = styled.div`
  background: ${p => p.theme.Colors.White};
  margin: 0;
  height: calc(100vh - ${p => p.theme.headerHeight});

  ${p =>
    p.theme.mq({
      padding: [16, 32, 32, 32, 64],
    })}
`

interface PushProps {
  pushed: boolean
}

const PushedContent = styled.div<PushProps>`
  margin-top: ${p => p.theme.headerHeight};
  ${p =>
    p.theme.mq({
      paddingLeft: [
        0,
        null,
        null,
        null,
        null,
        p.pushed ? p.theme.sidebarWidth : 0,
      ],
    })}
`

const Container = styled.div<PushProps>`
  max-width: 1600px;
  margin: ${p => (p.pushed ? '0' : 'auto')};
`

export const PageContent: React.FC<PageProps> = ({ children, doc }) => {
  const { isAtLeastXL } = useContext(MainContext)
  const { hero, filepath, parent } = doc
  const showSidebar = Boolean(parent)

  return (
    <Fragment>
      <PushedContent pushed={showSidebar}>
        {hero && <HeroImage src={hero} />}
        <Container pushed={showSidebar}>
          <Row>
            <Col xs={24} xl={showSidebar ? 19 : 24}>
              <Document>
                {children}
                {filepath && <EditOnGithub filepath={filepath} />}
              </Document>
            </Col>
            {isAtLeastXL && showSidebar && (
              <Col xl={4}>
                <TableOfContents doc={doc} />
              </Col>
            )}
          </Row>
        </Container>
      </PushedContent>
    </Fragment>
  )
}
