const BASE = 16
const HEX_REGEX = /^#?[a-fA-F0-9]+$/
const HEX_SHORTHAND_LENGTH = 3
const HEX_LENGTH = 6

/**
 * Converts Hex codes to RGBA.
 *
 * @param  {String}        hex     - The hexadecimal.
 * @param  {Number|String} [alpha] - The alpha transparency.
 * @return {String}                - The RGBA.
 */
export const hex2rgba = (hex: string, alpha: number = 1): string => {
  if (!HEX_REGEX.test(hex)) {
    throw Error('hex2rgba: first argument has invalid hexadecimal characters')
  }

  // trim unnecessary characters
  if (hex[0] === '#') {
    hex = hex.slice(1)
  }

  // expand shorthand
  if (hex.length === HEX_SHORTHAND_LENGTH) {
    const hexArray = hex.split('')
    hexArray.splice(2, 0, hexArray[2])
    hexArray.splice(1, 0, hexArray[1])
    hexArray.splice(0, 0, hexArray[0])
    hex = hexArray.join('')
  }

  if (hex.length !== HEX_LENGTH) {
    throw Error('hex2rgba: first argument has invalid hexadecimal length')
  }

  // convert hex to rgb
  var values = [
    parseInt(hex.slice(0, 2), BASE),
    parseInt(hex.slice(2, 4), BASE),
    parseInt(hex.slice(4, 6), BASE),
  ]

  alpha = typeof alpha === 'number' ? alpha : parseFloat(alpha)
  if (alpha >= 0 && alpha <= 1) {
    values.push(alpha)
  } else {
    values.push(1)
  }

  return 'rgba(' + values.join(',') + ')'
}
