import React from 'react'
import BaseCheck from 'react-feather/dist/icons/check'
import Clipboard from 'react-feather/dist/icons/clipboard'
import copy from 'copy-text-to-clipboard'
import styled from 'styled-components'

import { ButtonSwap } from './ButtonSwap'

export const ActionButton = styled(ButtonSwap)`
  padding: ${p =>
    `${p.theme.SpacingValues.XXSmall}px ${p.theme.SpacingValues.XSmall}px`};
  border-radius: 4px;
  background: ${p => p.theme.Colors.White};
  text-transform: uppercase;
  transition: color 0.3s;
`

const Check = styled(BaseCheck)`
  stroke: ${p => p.theme.Colors.Primary};
`

interface ClipboardActionProps {
  content: string
}

export const ClipboardAction: React.FC<ClipboardActionProps> = ({
  content,
  ...props
}) => (
  <ActionButton
    {...props}
    title="Copy to clipboard"
    onClick={() => copy(content)}
    swap={<Check width={17} />}
  >
    <Clipboard width={15} />
  </ActionButton>
)
