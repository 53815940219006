import React, { useState } from 'react'

import styled from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
`

export const ButtonSwap: React.FC<any> = ({
  onClick,
  swap,
  children,
  ...props
}) => {
  const hasSwap = Boolean(swap)
  const [on, setOn] = useState(() => false)
  const toggle = () => setOn(s => !s)

  const handleClick = (ev: any) => {
    onClick && onClick(ev)
    hasSwap && toggle()
    hasSwap && setTimeout(toggle, 500)
  }

  return (
    <Button size="small" onClick={handleClick} {...props}>
      {on ? swap : children}
    </Button>
  )
}
