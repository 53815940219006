import React from 'react'
import styled from 'styled-components'
import { Link } from '@theme/components'
import Icon from '@ant-design/icons'
import {
  Heading,
  Card,
  CardBody,
  ArrowRightIcon,
  CardFooter,
  Paragraph,
  CardProps,
} from '@generalassembly/cog'

interface TileProps {
  iconPath: string
  title?: string
  link: string
  body?: string
  equalizeHeight?: boolean
}

interface StyledCardProps extends CardProps {
  equalizeHeight: boolean
}

const CardLink = styled(Link)`
  color: ${p => p.theme.Colors.Body};
`

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin: ${p => p.theme.SpacingHelpers.Stack.Medium};
`

// https://www.darrenlester.com/blog/prevent-all-props-being-passed
const StyledCard = styled(({ equalizeHeight, ...props }: StyledCardProps) => (
  <Card {...props} />
))`
  height: ${p => (p.equalizeHeight ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardFooterRight = styled.div`
  text-align: right;
`

const StyledImg = styled.img`
  display: block;
  margin-right: 12px;
`

export const Tile: React.FC<TileProps> = ({
  iconPath,
  title,
  link,
  body,
  equalizeHeight = false,
  ...rest
}) => {
  return (
    <CardLink to={link} {...rest}>
      <StyledCard hoverable equalizeHeight={equalizeHeight}>
        <CardBody>
          <StyledCardHeader>
            <StyledImg src={iconPath} alt={title} />
            {title && <Heading type="paragraph-header">{title}</Heading>}
          </StyledCardHeader>
          {body && <Paragraph color="grey-500">{body}</Paragraph>}
        </CardBody>
        <CardFooter>
          <CardFooterRight>
            <Icon component={ArrowRightIcon} />
          </CardFooterRight>
        </CardFooter>
      </StyledCard>
    </CardLink>
  )
}
