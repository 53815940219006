export const imports = {
  'src/docs/brand/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-brand-index" */ 'src/docs/brand/index.mdx'
    ),
  'src/docs/brand/photography.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-brand-photography" */ 'src/docs/brand/photography.mdx'
    ),
  'src/docs/brand/tone-of-voice.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-brand-tone-of-voice" */ 'src/docs/brand/tone-of-voice.mdx'
    ),
  'src/docs/brand/visual-identity.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-brand-visual-identity" */ 'src/docs/brand/visual-identity.mdx'
    ),
  'src/docs/cog/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-index" */ 'src/docs/cog/index.mdx'
    ),
  'src/docs/copy/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-copy-index" */ 'src/docs/copy/index.mdx'
    ),
  'src/docs/tailwind/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-index" */ 'src/docs/tailwind/index.mdx'
    ),
  'src/docs/home/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-home-index" */ 'src/docs/home/index.mdx'
    ),
  'src/docs/cog/contributing/code-of-conduct.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-contributing-code-of-conduct" */ 'src/docs/cog/contributing/code-of-conduct.mdx'
    ),
  'src/docs/cog/contributing/contributing.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-contributing-contributing" */ 'src/docs/cog/contributing/contributing.mdx'
    ),
  'src/docs/cog/contributing/governance.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-contributing-governance" */ 'src/docs/cog/contributing/governance.mdx'
    ),
  'src/docs/cog/get-started/designers.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-get-started-designers" */ 'src/docs/cog/get-started/designers.mdx'
    ),
  'src/docs/cog/get-started/engineers.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-get-started-engineers" */ 'src/docs/cog/get-started/engineers.mdx'
    ),
  'src/docs/cog/guidelines/colors.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-guidelines-colors" */ 'src/docs/cog/guidelines/colors.mdx'
    ),
  'src/docs/cog/guidelines/email.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-guidelines-email" */ 'src/docs/cog/guidelines/email.mdx'
    ),
  'src/docs/cog/guidelines/icons.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-guidelines-icons" */ 'src/docs/cog/guidelines/icons.mdx'
    ),
  'src/docs/cog/guidelines/spacing.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-guidelines-spacing" */ 'src/docs/cog/guidelines/spacing.mdx'
    ),
  'src/docs/cog/help/faq.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-help-faq" */ 'src/docs/cog/help/faq.mdx'
    ),
  'src/docs/cog/help/support.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-help-support" */ 'src/docs/cog/help/support.mdx'
    ),
  'src/docs/cog/patterns/overview.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-patterns-overview" */ 'src/docs/cog/patterns/overview.mdx'
    ),
  'src/docs/tailwind/get-started/engineers.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-get-started-engineers" */ 'src/docs/tailwind/get-started/engineers.mdx'
    ),
  'src/docs/tailwind/guidelines/prefixed-class-names.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-guidelines-prefixed-class-names" */ 'src/docs/tailwind/guidelines/prefixed-class-names.mdx'
    ),
  'src/docs/tailwind/guidelines/responsive-design.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-guidelines-responsive-design" */ 'src/docs/tailwind/guidelines/responsive-design.mdx'
    ),
  'src/docs/tailwind/templates/templates.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-templates-templates" */ 'src/docs/tailwind/templates/templates.mdx'
    ),
  'src/docs/cog/components/data-display/avatar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-avatar" */ 'src/docs/cog/components/data-display/avatar.mdx'
    ),
  'src/docs/cog/components/data-display/card.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-card" */ 'src/docs/cog/components/data-display/card.mdx'
    ),
  'src/docs/cog/components/data-display/collapse.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-collapse" */ 'src/docs/cog/components/data-display/collapse.mdx'
    ),
  'src/docs/cog/components/data-display/label.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-label" */ 'src/docs/cog/components/data-display/label.mdx'
    ),
  'src/docs/cog/components/data-display/list.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-list" */ 'src/docs/cog/components/data-display/list.mdx'
    ),
  'src/docs/cog/components/data-display/popover.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-popover" */ 'src/docs/cog/components/data-display/popover.mdx'
    ),
  'src/docs/cog/components/data-display/reveal.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-reveal" */ 'src/docs/cog/components/data-display/reveal.mdx'
    ),
  'src/docs/cog/components/data-display/table.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-table" */ 'src/docs/cog/components/data-display/table.mdx'
    ),
  'src/docs/cog/components/data-display/tabs.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-tabs" */ 'src/docs/cog/components/data-display/tabs.mdx'
    ),
  'src/docs/cog/components/data-display/tag.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-tag" */ 'src/docs/cog/components/data-display/tag.mdx'
    ),
  'src/docs/cog/components/data-display/taglist.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-taglist" */ 'src/docs/cog/components/data-display/taglist.mdx'
    ),
  'src/docs/cog/components/data-display/tooltip.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-tooltip" */ 'src/docs/cog/components/data-display/tooltip.mdx'
    ),
  'src/docs/cog/components/data-display/tree.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-display-tree" */ 'src/docs/cog/components/data-display/tree.mdx'
    ),
  'src/docs/cog/components/data-entry/checkbox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-checkbox" */ 'src/docs/cog/components/data-entry/checkbox.mdx'
    ),
  'src/docs/cog/components/data-entry/input-number.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-input-number" */ 'src/docs/cog/components/data-entry/input-number.mdx'
    ),
  'src/docs/cog/components/data-entry/input.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-input" */ 'src/docs/cog/components/data-entry/input.mdx'
    ),
  'src/docs/cog/components/data-entry/radio.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-radio" */ 'src/docs/cog/components/data-entry/radio.mdx'
    ),
  'src/docs/cog/components/data-entry/select.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-select" */ 'src/docs/cog/components/data-entry/select.mdx'
    ),
  'src/docs/cog/components/data-entry/slider.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-slider" */ 'src/docs/cog/components/data-entry/slider.mdx'
    ),
  'src/docs/cog/components/data-entry/switch.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-switch" */ 'src/docs/cog/components/data-entry/switch.mdx'
    ),
  'src/docs/cog/components/data-entry/upload.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-data-entry-upload" */ 'src/docs/cog/components/data-entry/upload.mdx'
    ),
  'src/docs/cog/components/feedback/alert.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-alert" */ 'src/docs/cog/components/feedback/alert.mdx'
    ),
  'src/docs/cog/components/feedback/drawer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-drawer" */ 'src/docs/cog/components/feedback/drawer.mdx'
    ),
  'src/docs/cog/components/feedback/message.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-message" */ 'src/docs/cog/components/feedback/message.mdx'
    ),
  'src/docs/cog/components/feedback/modal.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-modal" */ 'src/docs/cog/components/feedback/modal.mdx'
    ),
  'src/docs/cog/components/feedback/notification.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-notification" */ 'src/docs/cog/components/feedback/notification.mdx'
    ),
  'src/docs/cog/components/feedback/popconfirm.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-popconfirm" */ 'src/docs/cog/components/feedback/popconfirm.mdx'
    ),
  'src/docs/cog/components/feedback/progressbar.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-feedback-progressbar" */ 'src/docs/cog/components/feedback/progressbar.mdx'
    ),
  'src/docs/cog/components/general/button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-general-button" */ 'src/docs/cog/components/general/button.mdx'
    ),
  'src/docs/cog/components/general/logo.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-general-logo" */ 'src/docs/cog/components/general/logo.mdx'
    ),
  'src/docs/cog/components/general/utilities.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-general-utilities" */ 'src/docs/cog/components/general/utilities.mdx'
    ),
  'src/docs/cog/components/layout/box.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-layout-box" */ 'src/docs/cog/components/layout/box.mdx'
    ),
  'src/docs/cog/components/layout/divider.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-layout-divider" */ 'src/docs/cog/components/layout/divider.mdx'
    ),
  'src/docs/cog/components/layout/keyline.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-layout-keyline" */ 'src/docs/cog/components/layout/keyline.mdx'
    ),
  'src/docs/cog/components/navigation/breadcrumb.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-navigation-breadcrumb" */ 'src/docs/cog/components/navigation/breadcrumb.mdx'
    ),
  'src/docs/cog/components/navigation/dropdown.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-navigation-dropdown" */ 'src/docs/cog/components/navigation/dropdown.mdx'
    ),
  'src/docs/cog/components/navigation/menu.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-navigation-menu" */ 'src/docs/cog/components/navigation/menu.mdx'
    ),
  'src/docs/cog/components/navigation/pagination.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-navigation-pagination" */ 'src/docs/cog/components/navigation/pagination.mdx'
    ),
  'src/docs/cog/components/navigation/steps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-navigation-steps" */ 'src/docs/cog/components/navigation/steps.mdx'
    ),
  'src/docs/cog/components/text/heading.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-text-heading" */ 'src/docs/cog/components/text/heading.mdx'
    ),
  'src/docs/cog/components/text/links.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-text-links" */ 'src/docs/cog/components/text/links.mdx'
    ),
  'src/docs/cog/components/text/paragraph.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-text-paragraph" */ 'src/docs/cog/components/text/paragraph.mdx'
    ),
  'src/docs/tailwind/components/general/button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-components-general-button" */ 'src/docs/tailwind/components/general/button.mdx'
    ),
  'src/docs/tailwind/components/general/forms.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-components-general-forms" */ 'src/docs/tailwind/components/general/forms.mdx'
    ),
  'src/docs/tailwind/components/general/grid.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-components-general-grid" */ 'src/docs/tailwind/components/general/grid.mdx'
    ),
  'src/docs/tailwind/components/general/link.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-tailwind-components-general-link" */ 'src/docs/tailwind/components/general/link.mdx'
    ),
  'src/docs/cog/components/layout/grid/grid.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-layout-grid-grid" */ 'src/docs/cog/components/layout/grid/grid.mdx'
    ),
  'src/docs/cog/components/text/markdown/markdown.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-cog-components-text-markdown-markdown" */ 'src/docs/cog/components/text/markdown/markdown.mdx'
    ),
}
