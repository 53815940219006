import React from 'react'
import styled from 'styled-components'
import SearchIcon from 'react-feather/dist/icons/search'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${p =>
    `${p.theme.SpacingValues.XXSmall}px ${p.theme.SpacingValues.Large}px`};
  margin: ${p => p.theme.SpacingHelpers.Stack.XSmall};
  border: none;
  opacity: 1;
`

const Icon = styled(SearchIcon)`
  stroke: ${p => p.theme.Colors.White};
  min-width: 20px;
  opacity: 0.5;
`

const Input = styled.input`
  outline: none;
  width: 100%;
  padding: ${p => p.theme.SpacingHelpers.Inset.XSmall};
  background: transparent;
  border: none;
  font-size: 16px;
  color: ${p => p.theme.Colors.White};
`

interface SearchProps {
  onSearch: (value: string) => void
}

export const Search: React.FC<SearchProps> = ({ onSearch }) => (
  <Wrapper>
    <Icon />
    <Input
      type="text"
      placeholder="Search here..."
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch && onSearch(e.target.value)
      }}
    />
  </Wrapper>
)
