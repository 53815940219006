import React from 'react'
import { Markdown } from '@generalassembly/cog'
import { useCopyToClipboard } from 'react-use'
import { CopyIcon } from '@generalassembly/cog'
import { ChromelessButton } from '@theme/components'
import { Tooltip } from 'antd'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

interface CSSCodeBlockProps {
  content: string
}

const Wrapper = styled.div`
  position: relative;
`

const AbsoluteChromelessButton = styled(ChromelessButton)`
  position: absolute;
  top: 8px;
  right: 12px;
`

export const CSSCodeBlock: React.FC<CSSCodeBlockProps> = ({
  content,
  ...rest
}) => {
  const [copyCode, copyCodeToClipboard] = useCopyToClipboard()

  return (
    <Wrapper {...rest}>
      <Tooltip title={copyCode.value ? `Copied CSS` : 'Copy CSS'}>
        <AbsoluteChromelessButton onClick={() => copyCodeToClipboard(content)}>
          <Icon component={CopyIcon} />
        </AbsoluteChromelessButton>
      </Tooltip>

      <Markdown content={`\`\`\`less \n${content}\n \`\`\``} />
    </Wrapper>
  )
}
