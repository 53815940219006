import React from 'react'
import { keyframes } from 'styled-components'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const clockwise = keyframes`
  100% { transform: rotate(360deg); }
`
const counterClockwise = keyframes`
  100% { transform: rotate(-360deg); }
`

const SVGWrapper = styled.div`
  width: 38px;
  height: 35px;

  & #cog-big,
  & #cog-small {
    position: absolute;
    top: 0;
    left: 0;
  }

  & #cog-big {
    top: 10px;
    animation: ${clockwise} 1.5s linear infinite;
  }

  & #cog-small {
    top: 1.5px;
    left: 19.5px;
    animation: ${counterClockwise} 1.5s linear infinite;
  }
`

const Path = styled.path`
  fill: ${p => p.theme.Colors.Red};
  fillrule: nonzero;
`

const Circle = styled.circle`
  fill: ${p => p.theme.Colors.White};
`

const Cog = () => (
  <g>
    <Path d="M23.664 10.068l-3.075-.41a8.844 8.844 0 0 0-.86-2.075l1.885-2.464a.387.387 0 0 0-.034-.509l-2.19-2.19a.385.385 0 0 0-.508-.034l-2.465 1.885a8.844 8.844 0 0 0-2.075-.86l-.41-3.075A.388.388 0 0 0 13.55 0H10.45a.387.387 0 0 0-.383.336l-.41 3.075c-.734.199-1.43.49-2.075.86L5.12 2.386a.386.386 0 0 0-.509.034L2.42 4.61a.387.387 0 0 0-.034.508l1.885 2.464a8.822 8.822 0 0 0-.86 2.076l-3.075.41A.388.388 0 0 0 0 10.45v3.098c0 .193.143.357.336.383l3.075.41c.199.734.49 1.43.86 2.075L2.386 18.88a.387.387 0 0 0 .034.509l2.19 2.19a.386.386 0 0 0 .509.034l2.464-1.885a8.83 8.83 0 0 0 2.075.86l.41 3.075c.026.192.19.336.383.336h3.098a.387.387 0 0 0 .383-.336l.41-3.075a8.844 8.844 0 0 0 2.075-.86l2.464 1.885a.386.386 0 0 0 .51-.034l2.19-2.19a.387.387 0 0 0 .033-.508l-1.884-2.465c.369-.645.66-1.34.86-2.075l3.074-.41A.388.388 0 0 0 24 13.55V10.45a.387.387 0 0 0-.336-.383z" />
    <Circle cx="12" cy="12" r="4" />
  </g>
)

const Spinner = () => (
  <SVGWrapper>
    <svg id="cog-big" width="24" height="24" viewBox="0 0 24 24">
      <Cog />
    </svg>
    <svg id="cog-small" width="18" height="18" viewBox="0 0 24 24">
      <Cog />
    </svg>
  </SVGWrapper>
)

export const Loading: React.FC = rest => (
  <Wrapper {...rest}>
    <Spinner />
  </Wrapper>
)
