import React from 'react'
import styled from 'styled-components'

import { Tooltip } from 'antd'
import { useCopyToClipboard } from 'react-use'
import { Box, Paragraph } from '@generalassembly/cog'

import { ChromelessButton } from '@theme/components'
import { hex2rgba } from '@utils/color'

export enum ColorSwatchSize {
  small = 'small',
  large = 'large',
}

interface ColorSwatchProps {
  name: string
  hex?: string
  cmyk?: string
  rgba?: string
  pms?: string
  size?: keyof typeof ColorSwatchSize
}

const Wrapper = styled.div`
  border: 1px solid ${p => p.theme.Colors.Grey200};
`

const ColorSwatchHeader = styled.div<ColorSwatchProps>`
  height: ${p => (p.size === ColorSwatchSize.small ? '100px' : '200px')};
  background-color: ${p => p.hex};
  background-color: ${p => p.rgba};
`

const ColorSwatchFooter = styled.div`
  padding: ${p => p.theme.SpacingHelpers.Inset.Small};
  width: 100%;
`

const getRgba = (rgba: string | undefined, hex: string | undefined) => {
  return rgba ? rgba : hex ? hex2rgba(hex) : null
}

export const ColorSwatch: React.FC<ColorSwatchProps> = ({
  name,
  hex,
  cmyk,
  rgba,
  pms,
  size = ColorSwatchSize.small,
  ...rest
}) => {
  const [copyHex, copyHexToClipboard] = useCopyToClipboard()
  const [copyRgb, copyRgbToClipboard] = useCopyToClipboard()
  const [copyCmyk, copyCmykToClipboard] = useCopyToClipboard()
  const [copyToken, copyTokenToClipboard] = useCopyToClipboard()
  const [copyPms, copyPmsToClipboard] = useCopyToClipboard()
  const rgbaStr = getRgba(rgba, hex)

  return (
    <Wrapper {...rest}>
      <ColorSwatchHeader name={name} size={size} hex={hex} rgba={rgba} />
      <ColorSwatchFooter>
        <Box margin="stack-sm">
          <Tooltip title={copyToken.value ? `Copied token` : 'Copy token'}>
            <ChromelessButton
              onClick={() => copyTokenToClipboard(`Colors.${name}`)}
            >
              <Paragraph>
                <b>{name}</b>
              </Paragraph>
            </ChromelessButton>
          </Tooltip>
        </Box>
        {hex && (
          <Box>
            <Tooltip title={copyHex.value ? `Copied ${hex}` : 'Copy Hex'}>
              <ChromelessButton onClick={() => copyHexToClipboard(hex)}>
                <Paragraph size="small">
                  <b>HEX:</b> {hex}
                </Paragraph>
              </ChromelessButton>
            </Tooltip>
          </Box>
        )}
        {rgbaStr && (
          <Box>
            <Tooltip title={copyRgb.value ? `Copied ${rgbaStr}` : 'Copy RGBA'}>
              <ChromelessButton onClick={() => copyRgbToClipboard(rgbaStr)}>
                <Paragraph size="small">
                  <b>RGBA:</b> {rgbaStr}
                </Paragraph>
              </ChromelessButton>
            </Tooltip>
          </Box>
        )}
        {cmyk && (
          <Box>
            <Tooltip title={copyCmyk.value ? `Copied ${cmyk}` : 'Copy CMYK'}>
              <ChromelessButton onClick={() => copyCmykToClipboard(cmyk)}>
                <Paragraph size="small">
                  <b>CMYK:</b> {cmyk}
                </Paragraph>
              </ChromelessButton>
            </Tooltip>
          </Box>
        )}
        {pms && (
          <Box>
            <Tooltip title={copyPms.value ? `Copied ${pms}` : 'Copy PMS'}>
              <ChromelessButton onClick={() => copyPmsToClipboard(pms)}>
                <Paragraph size="small">
                  <b>PMS:</b> {pms}
                </Paragraph>
              </ChromelessButton>
            </Tooltip>
          </Box>
        )}
      </ColorSwatchFooter>
    </Wrapper>
  )
}
