import React, { ReactNode } from 'react'
import styled from 'styled-components'
import slugify from '@sindresorhus/slugify'

import Icon from '@ant-design/icons'
import { LinkIcon } from '@generalassembly/cog'
import { Link } from '../Link'
import { useCopyToClipboard } from 'react-use'

const HeadingWrapper = styled.div`
  position: relative;

  &:hover {
    .anchor {
      opacity: 1;
    }
  }
`

const AnchorLink = styled(Link)`
  opacity: 0;
  transition: opacity 250ms ease;
  position: absolute;
  left: -${p => `${p.theme.SpacingValues.Medium}px`};
  top: 50%;
  transform: translateY(-50%);

  &:hover,
  &:active {
    opacity: 1;
  }
`

interface AnchorProps {
  id: string
  str: string
}

interface AutolinkHeader {
  children: ReactNode
}

const Anchor: React.FC<AnchorProps> = ({ id, str }) => {
  const [, copyLinkToClipboard] = useCopyToClipboard()
  const hash = `#${id}`
  const { pathname, origin } = window.location
  const linkToCopy = `${origin}${pathname}/${hash}`
  return (
    <AnchorLink
      to={hash}
      className="anchor-link"
      aria-label={`${str} permalink`}
      onClick={e => copyLinkToClipboard(linkToCopy)}
    >
      <Icon component={LinkIcon} />
    </AnchorLink>
  )
}

export const AutolinkHeader: React.FC<AutolinkHeader> = props => {
  const str = React.Children.map(props.children, (child: any) =>
    child.props ? child.props.children : child,
  ).join('')

  const id = slugify(str)

  return (
    <HeadingWrapper {...props}>
      <Anchor id={id} str={str} />
      {props.children}
    </HeadingWrapper>
  )
}
