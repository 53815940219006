import React, { useRef } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { useCopyToClipboard } from 'react-use'
import { Paragraph } from '@generalassembly/cog'
import { ChromelessButton } from '@theme/components'

import downloadSVG from './downloadSvg'

interface IconSwatchProps {
  component: React.ComponentType<
    CustomIconComponentProps | React.SVGProps<SVGSVGElement>
  >
  height: number
  name: string
}

const Wrapper = styled.div`
  border: 1px solid ${p => p.theme.Colors.Grey200};
`

const IconSwatchBody = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const IconSwatchFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${p => p.theme.Colors.Grey200};
  padding: ${p => p.theme.SpacingHelpers.Inset.Small};
  width: 100%;
`

export const IconSwatch: React.FC<IconSwatchProps> = ({
  component,
  height,
  name,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleDownload = () => {
    if (ref.current) {
      const svg: SVGSVGElement = ref.current.getElementsByTagName('svg')[0]
      const clone: SVGSVGElement = svg.cloneNode(true) as SVGSVGElement
      clone.setAttribute('width', `${height}px`)
      clone.setAttribute('height', `${height}px`)
      downloadSVG(clone, name)
    }
  }

  const [copyToken, copyTokenToClipboard] = useCopyToClipboard()

  return (
    <Wrapper {...rest} ref={ref}>
      <Tooltip title={`Download ${name}`}>
        <IconSwatchBody onClick={handleDownload}>
          <Icon
            component={component}
            style={{
              fontSize: height,
            }}
          />
        </IconSwatchBody>
      </Tooltip>
      <IconSwatchFooter>
        <Tooltip title={copyToken.value ? `Copied token` : 'Copy token'}>
          <ChromelessButton onClick={() => copyTokenToClipboard(`${name}`)}>
            <Paragraph>{name}</Paragraph>
          </ChromelessButton>
        </Tooltip>
      </IconSwatchFooter>
    </Wrapper>
  )
}
