import facepaint from 'facepaint'
import { MediaQuery } from '@generalassembly/cog'

export const mq = facepaint(
  [
    `@media(min-width: ${MediaQuery.XSmall}px)`,
    `@media(min-width: ${MediaQuery.Small}px)`,
    `@media(min-width: ${MediaQuery.Medium}px)`,
    `@media(min-width: ${MediaQuery.Large}px)`,
    `@media(min-width: ${MediaQuery.XLarge}px)`,
    `@media(min-width: ${MediaQuery.XXLarge}px)`,
  ],
  { overlap: true },
)
