import React from 'react'
import styled from 'styled-components'

const FullWidthImage = styled.div<HeroImageProps>`
  background-image: url("${p => p.src}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;

  ${p =>
    p.theme.mq({
      height: [240, null, null, 300, null, 380, 550],
    })}
`

interface HeroImageProps {
  src: string
}

export const HeroImage: React.FC<HeroImageProps> = ({ src, ...rest }) => {
  return <FullWidthImage {...rest} src={src} />
}
