import React from 'react'

import styled from 'styled-components'
import { Colors, SpacingValues, SpacingHelpers } from '@generalassembly/cog'

const flatten = (str: string) => {
  return str.replace(/{|}/g, '').trim()
}

const emailFontStack = 'font-family: Circular, Helvetica, Arial, sans-serif;'

const Header = `{
  ${emailFontStack}
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  color: ${Colors.Body};
}`

const HeaderMobile = `{
  ${emailFontStack}
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
  color: ${Colors.Body};
}`

const Body = `{
  ${emailFontStack}
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}`

const FinePrint = `{
  ${emailFontStack}
  font-size: 13.061px;
  font-weight: normal;
  line-height: 20px;
  color: ${Colors.Grey500};
}`

const Eyebrow = `{
  ${emailFontStack}
  font-size: 13.061px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 20px;
  color: ${Colors.Body};
}`

const BodyGrey = `{
  ${flatten(Body)}
  color: ${Colors.Grey500};
}`

const FinePrintLink = `{
  ${flatten(FinePrint)}
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}`

const Link = `{
  ${flatten(Body)}
  color: ${Colors.Links.Default};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}`

const Button = `{
  ${emailFontStack}
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.White};
  background-color: ${Colors.Red};
  border: none;
  border-radius: 4px;
  padding: ${SpacingValues.Medium / 2}px ${SpacingValues.Medium}px;
  cursor: pointer;

  &:hover {
    background-color: #ea474e;
    color: ${Colors.White};
  }
}`

const Divider = `{
  height: 1px;
  width: 100%;
  margin: ${SpacingHelpers.Vertical.Large};
  clear: both;
  color: ${Colors.Grey300};
}`

const Table = `{
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-radius: 4px 4px 0 0;
  }

  th {
    background: ${Colors.Grey100};
    border-bottom: 1px solid ${Colors.Grey300};
    color: ${Colors.Body};
    font-weight: 500;
    padding: ${SpacingHelpers.Inset.Small};
  }

  tr {
    border: 0;
    border-bottom: 1px solid ${Colors.Grey200};
    padding: ${SpacingHelpers.Inset.Small};
    vertical-align: top;

    &:last-child {
      border-bottom: 0;
    }
  }
}`

const StackMargin = `margin-bottom: ${SpacingValues.Large}px;`

const InsetPadding = `padding: ${SpacingValues.XLarge}px;`

const StyledButton = styled.button`
  ${Button}
`

const StyledDivider = styled.hr`
  ${Divider}
`

const StyledTable = styled.table`
  ${Table}
`

export interface ComponentStyle {
  component: JSX.Element
  css: string
}

export interface ComponentStyleMap {
  [key: string]: ComponentStyle
}

export const componentStyles: ComponentStyleMap = {
  Button: {
    component: <StyledButton>Button</StyledButton>,
    css: Button,
  },
  Divider: {
    component: <StyledDivider />,
    css: Divider,
  },
  Table: {
    component: (
      <StyledTable>
        <thead>
          <tr>
            <th>Table Head</th>
            <th>Table Head</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Table Data</td>
            <td>Table Data</td>
          </tr>
          <tr>
            <td>Table Data</td>
            <td>Table Data</td>
          </tr>
        </tbody>
      </StyledTable>
    ),
    css: Table,
  },
}

export const typeStyles: Record<string, string> = {
  Header,
  HeaderMobile,
  Eyebrow,
  Body,
  BodyGrey,
  FinePrint,
  FinePrintLink,
  Link,
}

export const spaceStyles: Record<string, string> = {
  StackMargin,
  InsetPadding,
}
