import styled from 'styled-components'

import { Table } from '@theme/components'

export const SmallTable = styled(Table)`
  & tbody td {
    padding: ${p =>
      `${p.theme.SpacingValues.XSmall}px ${p.theme.SpacingValues.Medium}px`};
  }
`
