import React from 'react'
import { Link as DoczLink, LinkProps as DoczLinkProps } from 'docz'

interface LinkProps extends DoczLinkProps<{}> {
  ref?: any
}

export const Link = (props: LinkProps) =>
  /^https?:\/\//.test(props.to || '') ? (
    <a target="_blank" rel="noopener noreferrer" href={props.to} {...props} />
  ) : (
    <DoczLink {...props} />
  )
