import React from 'react'

import { CSSGrid } from '@theme/components'
import { SpaceSwatch, SpacingTypes } from '../SpaceSwatch'

interface SpaceSwatchLooperProps {
  tokens: Record<string, string>
  type: SpacingTypes
}

export const SpaceSwatchLooper: React.FC<SpaceSwatchLooperProps> = ({
  tokens,
  type,
  ...rest
}) => (
  <CSSGrid {...rest} minSpanWidth={240} gap={32}>
    {Object.keys(tokens).map(key => (
      <SpaceSwatch
        key={key}
        name={key.toString()}
        type={type}
        value={tokens[key].toString()}
      />
    ))}
  </CSSGrid>
)
