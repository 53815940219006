import React from 'react'
import styled from 'styled-components'
import { useCopyToClipboard } from 'react-use'
import { Tooltip } from 'antd'
import { Paragraph, Colors } from '@generalassembly/cog'

import { ChromelessButton } from '@theme/components'

export enum SpacingTypes {
  Inset = 'Inset',
  Stack = 'Stack',
  Inline = 'Inline',
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

interface SpaceSwatchProps {
  name: string
  type: SpacingTypes
  value: string
}

interface SpaceSwatchHeaderProps {
  type: SpacingTypes
  value: string
}

interface StyleFunctionProps {
  (type: SpacingTypes, value?: string): string
}

const extract = (tokenValue: string) => {
  return tokenValue.split(' ').filter(value => /[px]/i.test(value))[0] || null
}

const getBoxShadow: StyleFunctionProps = (type, value = '0px') => {
  const px = extract(value)

  switch (type) {
    case SpacingTypes.Inset:
      return `inset 0 0 0 ${value} ${Colors.Primary}`
    case SpacingTypes.Stack:
      return `inset 0 -${px} ${Colors.Teal100}`
    case SpacingTypes.Inline:
      return `inset -${px} 0 0 0 ${Colors.Warning}`
    case SpacingTypes.Horizontal:
      return `inset 0 0 0 ${Colors.Success}, inset ${px} 0 0 ${
        Colors.Success
      }, inset 0 0 0px ${Colors.Success}, inset -${px} 0 0 ${Colors.Success}`
    case SpacingTypes.Vertical:
      return `inset 0 -${px} 0 ${Colors.Info}, inset 0 0 0 ${
        Colors.Info
      }, inset 0 ${px} 0 ${Colors.Info}, inset 0 0 0 ${Colors.Info}`
    default:
      return 'none'
  }
}

const getBackground: StyleFunctionProps = type => {
  switch (type) {
    case SpacingTypes.Stack:
      return Colors.Backgrounds.Info
    case SpacingTypes.Inline:
      return Colors.Backgrounds.Warning
    case SpacingTypes.Horizontal:
      return Colors.Backgrounds.Success
    case SpacingTypes.Vertical:
      return Colors.Backgrounds.Info
    default:
      return 'transparent'
  }
}

const Wrapper = styled.div`
  border: 1px solid ${p => p.theme.Colors.Grey200};
`

const SpaceSwatchHeader = styled.div<SpaceSwatchHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background: ${p => getBackground(p.type)};
  box-shadow: ${p => getBoxShadow(p.type, p.value)};
`

const SpaceSwatchFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.SpacingHelpers.Inset.Small};
  width: 100%;
`

export const SpaceSwatch: React.FC<SpaceSwatchProps> = ({
  name,
  value,
  type,
  ...rest
}) => {
  const [copyToken, copyTokenToClipboard] = useCopyToClipboard()

  return (
    <Wrapper {...rest}>
      <SpaceSwatchHeader value={value} type={type}>
        {value}
      </SpaceSwatchHeader>
      <SpaceSwatchFooter>
        <Tooltip title={copyToken.value ? `Copied token` : 'Copy token'}>
          <ChromelessButton
            onClick={() =>
              copyTokenToClipboard(`SpacingHelpers.${type}.${name}`)
            }
          >
            <Paragraph margin="inline-xs">{name}</Paragraph>
          </ChromelessButton>
        </Tooltip>
      </SpaceSwatchFooter>
    </Wrapper>
  )
}
