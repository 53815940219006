import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { PageProps } from 'docz'

import useScrollToHash from '@theme/hooks/useScrollToHash'
import { Masthead, PageContent, Sidebar } from '@theme/components'
import usePageTracking from '@theme/hooks/usePageTracking'

export const Page: React.FC<PageProps> = ({ children, doc, location }) => {
  const hash = location && location.hash
  useScrollToHash(hash)
  usePageTracking(location ? location.href : '')

  return (
    <Fragment>
      <Helmet title={doc.name} />
      <Masthead />
      <Sidebar doc={doc} />
      <PageContent doc={doc}>{children}</PageContent>
    </Fragment>
  )
}
