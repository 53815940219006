import React from 'react'
import { useConfig } from 'docz'
import Maximize from 'react-feather/dist/icons/maximize'
import Minimize from 'react-feather/dist/icons/minimize'
import Refresh from 'react-feather/dist/icons/refresh-cw'
import Code from 'react-feather/dist/icons/code'
import styled, { css } from 'styled-components'

import { CodeSandboxLogo } from './CodeSandboxLogo'
import { ActionButton, ClipboardAction } from '../Editor/elements'

interface ActionsProps {
  theme: any
  withRadius: boolean
}

const Actions = styled.div<ActionsProps>`
  display: flex;
  justify-content: flex-end;
  padding: 0 5px;
  background: ${p => p.theme.Colors.White};
`

const actionStyle = css`
  padding: 3px 10px;
  border-left: 1px solid ${p => p.theme.Colors.Grey200};
`

const Action = styled(ActionButton)<React.ButtonHTMLAttributes<any>>`
  ${actionStyle};
`

const Clipboard = styled(ClipboardAction)`
  ${actionStyle};
`

export interface ActionsBarProps {
  code: string
  showEditor: boolean
  fullscreen: boolean
  codesandboxUrl: string | null
  onClickRefresh: () => void
  onClickFullscreen: () => void
  onClickEditor: () => void
}

export const ActionsBar: React.FC<ActionsBarProps> = ({
  showEditor,
  code,
  fullscreen,
  codesandboxUrl,
  onClickRefresh,
  onClickFullscreen,
  onClickEditor,
}) => {
  const config = useConfig()
  const hasSandbox = Boolean(config.codeSandbox)

  return (
    <Actions withRadius={showEditor}>
      <Action onClick={onClickRefresh} title="Refresh playground">
        <Refresh width={15} />
      </Action>
      {hasSandbox && (
        <Action
          as="a"
          href={codesandboxUrl}
          target="_blank"
          title="Open in CodeSandbox"
        >
          <CodeSandboxLogo style={{ height: '100%' }} width={15} />
        </Action>
      )}
      <Clipboard content={code} />
      <Action
        onClick={onClickFullscreen}
        title={fullscreen ? 'Minimize' : 'Maximize'}
      >
        {fullscreen ? <Minimize width={15} /> : <Maximize width={15} />}
      </Action>
      <Action
        onClick={onClickEditor}
        title={showEditor ? 'Close editor' : 'Show editor'}
      >
        <Code width={15} />
      </Action>
    </Actions>
  )
}
