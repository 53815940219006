import React from 'react'
import { Col, Row } from 'antd'
import { Box, Heading, Paragraph } from '@generalassembly/cog'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  margin-top: ${p => `${p.theme.SpacingValues.Large}px`};
  h1,
  p {
    max-width: none;
  }
`
export const HomeHero: React.FC = rest => (
  <StyledRow {...rest} justify="center" className="u-text-center">
    <Col md={14}>
      <Box margin="stack-xxl">
        <Heading margin="stack-md">Pursue the work you love</Heading>
        <Paragraph size="large" color="grey-500">
          Here you’ll find the tools to craft clean, bold, and sophisticated
          experiences that ultimately empower individuals to pursue the work
          they love.
        </Paragraph>
      </Box>
    </Col>
  </StyledRow>
)
