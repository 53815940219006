import React from 'react'
import styled from 'styled-components'

import { mq } from '@theme/styles/responsive'
import { SpacingValues, MediaQuery } from '@generalassembly/cog'

const Wrapper = styled.div`
  margin: ${p => p.theme.SpacingHelpers.Stack.Large};
  overflow-x: auto;
  ${mq({
    marginBottom: [SpacingValues.Medium, SpacingValues.XLarge],
    maxWidth: ['calc(100vw - 40px)', 'calc(100vw - 80px)', '100%'],
  })};
`
const minWidth = `${MediaQuery.Medium - 1}px`
const TableStyled = styled.table`
  padding: 0;
  table-layout: fixed;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 2px;
  color: ${p => p.theme.Colors.Body};
  width: 100%;
  overflow-y: hidden;

  ${mq({
    overflowX: ['initial', 'initial', 'initial', 'hidden'],
    minWidth: [minWidth, minWidth, minWidth, 'auto'],
  })}

  & thead th {
    font-weight: bold;
    border-bottom: 1px solid ${p => p.theme.Colors.Grey300};
    background: ${p => p.theme.Colors.Grey100};

    &:nth-of-type(1) {
      width: 22%;
    }

    &:nth-of-type(4) {
      width: 13%;
    }

    &:first-child + th:last-child {
      width: 67%;
    }
  }

  & thead th,
  & tbody td {
    padding: ${p =>
      `${p.theme.SpacingValues.Small}px ${p.theme.SpacingValues.Medium}px`};
  }

  & tbody td {
    &:nth-of-type(3) {
      word-break: break-all;
    }
  }

  & tbody tr {
    border-bottom: 1px solid ${p => p.theme.Colors.Grey300};
  }
`

const Table = (props: React.TableHTMLAttributes<any>) => (
  <Wrapper>
    <TableStyled {...props} />
  </Wrapper>
)

export default Table
