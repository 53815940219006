import React from 'react'
import { useConfig } from 'docz'
import { Divider, Box } from '@generalassembly/cog'

interface GithubLinkProps {
  filepath: string
}

export const EditOnGithub: React.FC<GithubLinkProps> = ({
  filepath,
  ...rest
}) => {
  const { repository } = useConfig()

  return (
    <Box margin="vertical-xxxl" {...rest}>
      <Divider />
      <a
        href={`${repository}/edit/master/${filepath}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Edit this page on Github"
      >
        Edit this page on Github
      </a>
    </Box>
  )
}
