import React from 'react'
import { Box, Heading } from '@generalassembly/cog'

// import ErrorPage from 'components/ErrorPage'

export interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({
      hasError: true,
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box padding="inset-md" theme="warning">
          <Heading type="paragraph-header">Error Boundary</Heading>
        </Box>
      )
    }

    return this.props.children
  }
}
