import React from 'react'

import { SpacingValues } from '@generalassembly/cog'
import { SmallTable } from '@theme/components'

export const SpaceValuesLooper: React.FC = rest => (
  <SmallTable {...rest}>
    <thead>
      <tr>
        <th>Property</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(SpacingValues).map(key => (
        <tr key={key}>
          <td>
            <b>{key}</b>
          </td>
          <td>{SpacingValues[key]}</td>
        </tr>
      ))}
    </tbody>
  </SmallTable>
)
