import React, { Fragment } from 'react'

export const InlineList: React.FC = ({ children }) => {
  return (
    <Fragment>
      {React.Children.map(children, child => (
        <div style={{ display: 'inline-block', margin: '0 8px' }}>{child}</div>
      ))}
    </Fragment>
  )
}
