import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { Hamburger } from '@theme/components/Sidebar/Hamburger'
import { Link } from '@theme/components'
import { Logo } from '@generalassembly/cog'
import MainContext from '@theme/contexts/MainContext'

const Wrapper = styled.div`
  z-index: 999;
  width: 100%;
  position: fixed;
  height: ${p => p.theme.headerHeight};
  background: ${p => p.theme.Colors.White};
  border-bottom: 1px solid ${p => p.theme.Colors.Grey200};

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${p =>
    p.theme.mq({
      padding: [
        `0 ${p.theme.SpacingValues.Small}px`,
        `0 ${p.theme.SpacingValues.Large}px`,
      ],
    })}
`

const Menu = styled.div`
  display: flex;
`

const linkStyle = (p: any) => css`
  color: ${p.theme.Colors.Body};
  opacity: 0.5;
  transition: opacity 0.2s;
  font-weight: 400;

  &.active,
  &:visited,
  &:hover {
    color: ${p.theme.Colors.Body};
    opacity: 1;
  }
`

const MenuLink = styled(Link)`
  ${linkStyle};
  margin: 0 10px;
`

export const IconLink = styled.a`
  ${linkStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;

  svg {
    stroke: ${p => p.theme.Colors.Body};
  }
`

export const Masthead: React.FC = rest => {
  const { showing, setShowing, mastheadLinks, isAtLeastXL } = useContext(
    MainContext,
  )

  return (
    <Wrapper {...rest}>
      <Link to="/">
        <Logo />
      </Link>
      <Menu>
        {isAtLeastXL &&
          mastheadLinks.map(({ id, children, ...props }) => {
            const Component = props.to ? MenuLink : IconLink
            return (
              <Component key={id} {...props}>
                {children}
              </Component>
            )
          })}
        {!isAtLeastXL && (
          <Hamburger
            opened={showing}
            onClick={() => setShowing((s: any) => !s)}
          />
        )}
      </Menu>
    </Wrapper>
  )
}
