import { useEffect } from 'react'
import snowplow from '../../services/snowplow'

const usePageTracking = (href: string) => {
  useEffect(() => {
    const GAplow = (window as any).GAplow

    if (!GAplow) {
      // @NOTE: Init also tracks first page view.
      // https://github.com/generalassembly/ga-snowplow-js/blob/master/app/assets/javascripts/ga-snowplow.js#L122
      snowplow.init()
    } else {
      GAplow('trackPageView')
    }
  }, [location])
}

export default usePageTracking
