import React from 'react'
import { Box } from '@generalassembly/cog'
import { BoxTheme } from '@generalassembly/cog/dist/Box'

interface NoteProps {
  theme?: BoxTheme
}

export const Note: React.FC<NoteProps> = ({ theme = 'info', children }) => (
  <Box theme={theme} padding="inset" margin="stack-md">
    {children}
  </Box>
)
