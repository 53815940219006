import React from 'react'
import { AutolinkHeader } from '@theme/components'
import { Heading } from '@generalassembly/cog'

const H4 = ({ ...rest }) => (
  <AutolinkHeader>
    <Heading type="eyebrow" margin="stack" {...rest} />
  </AutolinkHeader>
)

export default H4
