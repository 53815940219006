import { GASnowplowJS } from '@generalassembly/ga-snowplow-js'

// @NOTE: Docz does not allow for env vars, docz v2 might change this. This
// is trivial, so stopgap is use prod vars for all hosted applications.
const isHosted = process.env.NODE_ENV === 'production'

let SNOWPLOW_APP_ID: string
let SNOWPLOW_DEBUG: boolean
let SNOWPLOW_URL: string

if (isHosted) {
  SNOWPLOW_APP_ID = 'docz_production'
  SNOWPLOW_DEBUG = false
  SNOWPLOW_URL = 'sp.generalassemb.ly'
} else {
  SNOWPLOW_APP_ID = 'docz_dev'
  SNOWPLOW_DEBUG = true
  SNOWPLOW_URL = 'devsp.generalassemb.ly'
}

const snowplow = new GASnowplowJS({
  appId: SNOWPLOW_APP_ID,
  debug: SNOWPLOW_DEBUG,
  spCollector: SNOWPLOW_URL,
})

export default snowplow
