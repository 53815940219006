import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<CSSGridProps>`
  display: grid;
  margin: ${p => p.theme.SpacingHelpers.Stack.XXLarge};
  grid-template-columns: repeat(
    auto-fit,
    minmax(${p => p.minSpanWidth}px, 1fr)
  );
  grid-gap: ${p => `${p.gap}px`};
  align-items: ${p => p.align};
  & > * {
    grid-column: ${p => `span ${p.spans}`};
  }
`

interface CSSGridProps {
  minSpanWidth?: number
  spans?: number
  align?: string
  gap?: number
}

export const CSSGrid: React.FC<CSSGridProps> = ({
  children,
  minSpanWidth = 320,
  spans = 1,
  align = 'baseline',
  gap = 0,
  ...rest
}) => {
  return (
    <Wrapper
      {...rest}
      minSpanWidth={minSpanWidth}
      align={align}
      gap={gap}
      spans={spans}
    >
      {children}
    </Wrapper>
  )
}
