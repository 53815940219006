import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Tile } from '@theme/components'

const StyledCol = styled(Col)`
  margin: ${p => p.theme.SpacingHelpers.Stack.Large};
`

export const HomeTiles: React.FC = rest => (
  <Row {...rest} gutter={32}>
    <StyledCol xs={24} sm={12} md={8}>
      <Tile
        iconPath="/public/images/design.svg"
        title="Brand"
        body="Our brand story and high-level overview of key brand elements."
        link="/brand"
        equalizeHeight={true}
      />
    </StyledCol>
    <StyledCol xs={24} sm={12} md={8}>
      <Tile
        iconPath="/public/images/engineering.svg"
        title="Cog (React)"
        body="A React design system, used to build software experiences."
        link="/cog"
        equalizeHeight={true}
      />
    </StyledCol>
    <StyledCol xs={24} sm={12} md={8}>
      <Tile
        iconPath="/public/images/engineering.svg"
        title="Cog (Tailwind)"
        body="A Tailwind CSS system used in marketing experiences."
        link="/tailwind"
        equalizeHeight={true}
      />
    </StyledCol>
    <StyledCol xs={24} sm={12} md={8}>
      <Tile
        iconPath="/public/images/engineering.svg"
        title="External APIs"
        body="External API Documentation (Website API, CAN-SPAN, Learning API, Webhooks, etc.)"
        link="https://external.generalassemb.ly/documentation"
        equalizeHeight={true}
      />
    </StyledCol>
  </Row>
)
