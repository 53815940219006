import React, { Fragment } from 'react'

export const BlockList: React.FC = ({ children }) => {
  return (
    <Fragment>
      {React.Children.map(children, child => (
        <div style={{ margin: '8px 0' }}>{child}</div>
      ))}
    </Fragment>
  )
}
