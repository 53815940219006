import React from 'react'

import { SmallTable, CSSCodeBlock } from '@theme/components'
import { componentStyles } from '@theme/styles/email'

export const EmailComponentTable: React.FC = () => {
  return (
    <SmallTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Element</th>
          <th>CSS</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(componentStyles).map(key => {
          const Component = componentStyles[key].component as JSX.Element
          return (
            <tr key={key}>
              <td>{key}</td>
              <td>{Component}</td>
              <td>
                <CSSCodeBlock content={componentStyles[key].css} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </SmallTable>
  )
}
