import React from 'react'

import { SmallTable, CSSCodeBlock } from '@theme/components'
import { spaceStyles } from '@theme/styles/email'

export const EmailSpaceTable: React.FC = () => {
  return (
    <SmallTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>CSS</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(spaceStyles).map(key => {
          return (
            <tr key={key}>
              <td>{key}</td>
              <td>
                <CSSCodeBlock content={spaceStyles[key]} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </SmallTable>
  )
}
