import React from 'react'
import styled from 'styled-components'

import { SmallTable, CSSCodeBlock } from '@theme/components'
import { typeStyles } from '@theme/styles/email'

export const EmailTypeTable: React.FC = () => {
  return (
    <SmallTable>
      <thead>
        <tr>
          <th>Element</th>
          <th>CSS</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(typeStyles).map(key => {
          const Component = styled.div`
            ${typeStyles[key]}
          `
          return (
            <tr key={key}>
              <td>
                <Component>{key}</Component>
              </td>
              <td>
                <CSSCodeBlock content={typeStyles[key]} />
              </td>
            </tr>
          )
        })}
      </tbody>
    </SmallTable>
  )
}
