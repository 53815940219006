import {
  Colors,
  SpacingHelpers,
  SpacingValues,
  MediaQuery,
} from '@generalassembly/cog'

export const config = {
  Colors,
  SpacingHelpers,
  SpacingValues,
  MediaQuery,
  codemirrorTheme: 'one-dark',
  showPlaygroundEditor: true,
  linesToScrollEditor: 18,
  headerHeight: '56px',
  sidebarWidth: '320px',
  maxTextWidth: '800px',
}
