import React from 'react'

interface MastheadLink {
  id: number
  children: React.ReactNode
  [key: string]: any
}

interface MainContextState {
  isAtLeastXL: boolean
  mastheadLinks: MastheadLink[]
  showing: boolean
  setShowing: React.Dispatch<React.SetStateAction<boolean>>
}

const MainContext = React.createContext<MainContextState>({
  isAtLeastXL: false,
  mastheadLinks: [],
  showing: true,
  setShowing: () => {},
})

export default MainContext
