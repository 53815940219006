import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { theme, ComponentsProvider, useConfig } from 'docz'
import styled, { ThemeProvider } from 'styled-components'
import { useWindowSize } from 'react-use'

import { config } from './config'
import { MediaQuery } from '@generalassembly/cog'
import { mq } from '@theme/styles/responsive'
import { Global } from '@theme/styles/global'
import MainContext from '@theme/contexts/MainContext'
import {
  Page,
  Playground,
  NotFound,
  H1,
  H2,
  H3,
  H4,
  Table,
  Loading,
  Pre,
  Hr,
} from '@theme/components'

import 'cog/index.less'

import '@generalassembly/cog-tailwind-core/dist/css/utilities.min.css'

const map = {
  page: Page,
  playground: Playground,
  notFound: NotFound,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H4, // same as H4
  h6: H4, // same as H4
  table: Table,
  loading: Loading,
  pre: Pre,
  hr: Hr,

  // @TODO: make these all COG - AntD things?

  // img: components.Image,
  // ul: components.List,
  // pre: components.Pre,
  // inlineCode: components.Code,
  // blockquote: components.Blockquote,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Theme: React.FC = ({ children }) => {
  const config = useConfig()
  const [showing, setShowing] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const body = document.querySelector('body')
      const method = showing ? 'add' : 'remove'
      body && body.classList[method]('with-overlay')
    }
  }, [showing])

  useEffect(() => {
    if (width > MediaQuery.XLarge) {
      const body = document.querySelector('body')
      body && body.classList.remove('with-overlay')
      setShowing(false)
    }
  }, [width])

  const isAtLeastXL = width > MediaQuery.XLarge

  return (
    <ThemeProvider theme={{ ...config.themeConfig, mq }}>
      <ComponentsProvider components={map}>
        <Helmet
          defaultTitle={config.title}
          titleTemplate={`%s • ${config.title}`}
        />
        <Global />
        <MainContext.Provider
          value={{
            isAtLeastXL,
            showing,
            setShowing,
            mastheadLinks: [
              {
                id: 1,
                children: 'Home',
                to: '/',
              },
              {
                id: 2,
                children: 'Brand',
                to: '/brand',
              },
              {
                id: 3,
                children: 'Cog (React)',
                to: '/cog',
              },
              {
                id: 4,
                children: 'Cog (Tailwind)',
                to: '/tailwind',
              },
            ],
          }}
        >
          <Wrapper>{children}</Wrapper>
        </MainContext.Provider>
      </ComponentsProvider>
    </ThemeProvider>
  )
}

export default theme(config)(Theme)
