import React, { Fragment } from 'react'
import { navigate } from '@reach/router'
import { Entry } from 'docz'
import { Anchor } from 'antd'
import { SpacingValues } from '@generalassembly/cog'

interface TableOfContentsProps {
  doc: Entry
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ doc }) => {
  const headings = doc.headings.filter(
    heading => heading.depth > 1 && heading.depth < 3,
  )

  const { hero } = doc

  const handleClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode
      href: string
    },
  ) => {
    // We handle hash navigation changes in scrollToHash() on the <Page />
    e.preventDefault()
    // navigate programatically to the hash route to cause as scroll
    navigate(link.href)
  }

  return (
    <Fragment>
      {headings.length > 0 && (
        <Anchor
          offsetTop={hero ? 56 : 120}
          onClick={handleClick}
          style={{ paddingTop: hero ? `${SpacingValues.XXLarge}px` : 0 }}
        >
          {headings.map(heading => (
            <Anchor.Link
              key={heading.slug}
              href={`${doc.route}#${heading.slug}`}
              title={heading.value}
            />
          ))}
        </Anchor>
      )}
    </Fragment>
  )
}
