import React from 'react'
import styled from 'styled-components'

import { Box, Heading, Paragraph } from '@generalassembly/cog'
import { Link } from '@theme/components'

const FlexCenteredBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const NotFound = () => (
  <FlexCenteredBox padding="inset-lg">
    <Box className="u-text-center">
      <Heading margin="stack-md">Page not found</Heading>
      <Paragraph size="large" color="grey-500">
        Try heading back to the <Link to="/">home page</Link>
      </Paragraph>
    </Box>
  </FlexCenteredBox>
)
