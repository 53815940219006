import { createGlobalStyle, css } from 'styled-components'

const maxTextWidth = css`
  max-width: ${p => p.theme.maxTextWidth};
`

// tslint:disable
export const BaseStyle = createGlobalStyle`
  @import url('https://unpkg.com/codemirror@5.42.0/lib/codemirror.css');

  body.with-overlay,
  body.with-overlay #root {
    width: 100%;
    overflow: hidden;
    position: fixed;
  }

  h1,h2,h3,h4,h5,h6,p,ul,ol {
    ${maxTextWidth}
  }
`
