import React from 'react'
import {
  CheckCircle24Icon,
  ErrorCircle24Icon,
  Colors,
} from '@generalassembly/cog'
import Icon from '@ant-design/icons'
import styled, { css } from 'styled-components'

interface ExampleProps {
  correct?: boolean
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`
const AbsoluteIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  left: 12px;
`
const Border = css`1px solid ${p => p.theme.Colors.Grey200};`

const ExampleBody = styled.div<ExampleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 168px;
  height: 100%;
  padding: ${p => p.theme.SpacingHelpers.Inset.Small};
  border-top: 3px solid
    ${p => (p.correct ? p.theme.Colors.Success : p.theme.Colors.Error)};
  border-left: ${Border};
  border-right: ${Border};
  border-bottom: ${Border};
`
export const Example: React.FC<ExampleProps> = ({
  correct = true,
  ...props
}) => (
  <Wrapper {...props}>
    <AbsoluteIcon
      component={correct ? CheckCircle24Icon : ErrorCircle24Icon}
      style={{ color: correct ? Colors.Success : Colors.Error }}
    />
    <ExampleBody correct={correct}>{props.children}</ExampleBody>
  </Wrapper>
)
