import { useEffect } from 'react'

const scrollToHash = (hash: string) => {
  if (hash) {
    const id: string = hash.substring(1)
    const exists = setInterval(() => {
      const el: HTMLElement | null = document.getElementById(id)
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({
            behavior: 'smooth',
          })
        }, 250)
        clearInterval(exists)
      }
    }, 0)
  }
}

const useScrollToHash = (hash?: string) => {
  useEffect(() => {
    if (hash) scrollToHash(hash)
    return
  }, [hash])
}

export default useScrollToHash
