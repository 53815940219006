import React, { Fragment, useContext, useState } from 'react'
import { useMenus, PageProps, MenuItem } from 'docz'
import styled, { css } from 'styled-components'
import { Divider } from '@generalassembly/cog'

import { Link as BaseLink } from '@theme/components'
import MainContext from '@theme/contexts/MainContext'
import { Search } from './Search'
import { Menu } from './Menu'

const SidebarWrapper = styled.div<{
  isFullpage: boolean
  isOpen: boolean
  isMobile: boolean
}>`
  min-width: ${p => p.theme.sidebarWidth};
  height: calc(100% - ${p => p.theme.headerHeight});
  top: ${p => p.theme.headerHeight};
  background: ${p => p.theme.Colors.Backgrounds.Dark};
  transition: transform 0.3s;
  transform: translateX(${p => (!p.isOpen && p.isMobile ? '-100%' : '0')});
  overflow: auto;
  position: fixed;
  padding: ${p => `${p.theme.SpacingHelpers.Vertical.Medium}px`};
  display: flex;
  flex-direction: column;
  display: ${p => !p.isMobile && p.isFullpage && 'none'};

  ${p =>
    p.isMobile &&
    css`
      height: 100%;
      top: 0;
      left: 0;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 9999;
    `};
`

const Link = styled(BaseLink)`
  display: block;
  padding: ${p =>
    `${p.theme.SpacingValues.XSmall}px ${p.theme.SpacingValues.Large}px`};
  transition: background-color 0.3s;

  &.active {
    background-color: ${p => p.theme.Colors.Primary};

    &:hover {
      color: ${p => p.theme.Colors.White};
    }
  }

  &,
  &:visited {
    color: ${p => p.theme.Colors.White};
  }

  &:hover {
    color: ${p => p.theme.Colors.Primary};
  }
`

const Menus = styled.nav`
  flex-grow: 1;
  margin: ${p => `${p.theme.SpacingHelpers.Stack.Large}px`};

  h4 {
    padding: ${p => p.theme.SpacingHelpers.Horizontal.Large};
  }
`

const ExternalMenus = styled.nav`
  flex-shrink: 0;
`

const LightDivider = styled(Divider)`
  flex-shrink: 0;
  background: ${p => p.theme.Colors.Grey600};
`

const ToggleBackground = styled.div<{
  isOpen: boolean
}>`
  content: '';
  display: ${p => (!p.isOpen ? 'none' : 'block')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 98;
`

export const Sidebar: React.FC<PageProps> = ({ doc }) => {
  const [query, setQuery] = useState('')
  const [externalMenus, setExternalMenus] = useState<MenuItem[]>([])
  const menuParent = doc.parent || doc.name

  const { isAtLeastXL, mastheadLinks, showing, setShowing } = useContext(
    MainContext,
  )

  const menus = useMenus({
    query,
    filter: menu => {
      // if an external menu is under this parent seperate it so we can style it differently
      if (menu.href && menu.parent === menuParent) {
        setExternalMenus(menus => menus.concat(menu))
      }
      // accordion menus under this parent
      if (menu.menu) {
        return menu.menu.some(item => item.parent === menuParent)
      }
      // all other menus under this parent that aren't external
      return (
        menu.name !== menuParent && !menu.href && menu.parent === menuParent
      )
    },
  })

  const onSidebarToggle = () => {
    if (isAtLeastXL) return
    setShowing(s => !s)
  }

  return (
    <Fragment>
      <SidebarWrapper
        isOpen={showing}
        isMobile={!isAtLeastXL}
        isFullpage={doc.layout === 'fullpage'}
      >
        <Fragment>
          <Search onSearch={setQuery} />
          {!isAtLeastXL && (
            <Fragment>
              {mastheadLinks.map(({ id, children, ...props }) => (
                <Link key={id} {...props} onClick={onSidebarToggle}>
                  {children}
                </Link>
              ))}
              <LightDivider />
            </Fragment>
          )}
          {menus && menus.length > 0 && (
            <Menus>
              {menus.map(menu => (
                <Menu
                  key={menu.id}
                  item={menu}
                  sidebarToggle={onSidebarToggle}
                  collapseAll={Boolean(query.length)}
                />
              ))}
            </Menus>
          )}
          {externalMenus && externalMenus.length > 0 && (
            <ExternalMenus>
              {externalMenus.map(menu => (
                <Menu
                  key={menu.id}
                  item={menu}
                  sidebarToggle={onSidebarToggle}
                  collapseAll={Boolean(query.length)}
                />
              ))}
            </ExternalMenus>
          )}
        </Fragment>
      </SidebarWrapper>
      {!isAtLeastXL && (
        <ToggleBackground isOpen={showing} onClick={onSidebarToggle} />
      )}
    </Fragment>
  )
}
